import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import { GamificationSettingsRes, LeaderboardRes, GamificationBadgeSetsRes } from "types/responses";

export const getLeaderboard = async (): Promise<LeaderboardRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.leaderboard);

  return res.data;
};

export const getGamificationSettings = async (): Promise<GamificationSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.settings);

  return res.data;
};

export const getBadgeSets = async (): Promise<GamificationBadgeSetsRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.badgeSets);

  return res.data;
};
