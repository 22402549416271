import { RuleConfiguration } from "./constants";
import { GroupedOption, SelectOption } from "types/common";
import { MultiValue, SingleValue } from "react-select";
import { ColumnsType, CustomCourseFieldsFilter, CustomUserFieldsFilter, RuleValues } from "./types";
import { t } from "i18next";
import { RulesType } from "../types";

export const getCustomReportRuleOptions = (
  configurations: Record<string, RuleConfiguration>,
): SelectOption[] => {
  return Object.entries(configurations).map(([key, config]) => ({
    label: config.label,
    value: key,
  }));
};
export const getDefaultReportOutputColumns = (
  reportOutputColumns: ColumnsType[],
  customUserFields: CustomUserFieldsFilter[],
  customCourseFields?: CustomCourseFieldsFilter[],
): GroupedOption[] => {
  const customUserFieldsArray = customUserFields.map((option) => ({
    label: option.name,
    value: `customUserField${option.id}`,
  }));

  const result = [
    {
      label: t("general.general"),
      options: reportOutputColumns.map((column) => ({
        label: column.label,
        value: column.value,
      })),
    },
    {
      label: t("accountAndSettings.usersSettings.customUserFields"),
      options: customUserFieldsArray,
    },
  ];

  if (customCourseFields) {
    const customCourseFieldsArray = customCourseFields.map((option) => ({
      label: option.name,
      value: `customCourseField${option.id}`,
    }));

    result.push({
      label: t("accountAndSettings.courseSettings.customCourseFields"),
      options: customCourseFieldsArray,
    });
  }
  return result;
};

export const getMappedData = (
  secondArray: string[] | null,
  groupedOptions: GroupedOption[],
): SelectOption[] => {
  if (!secondArray) return [];
  return secondArray
    .map((item) => {
      let foundItem = null;
      for (const group of groupedOptions) {
        foundItem = group.options.find((option) => option.value === item);
        if (foundItem) break;
      }
      return foundItem ? { label: foundItem.label, value: foundItem.value } : null;
    })
    .filter((item): item is { label: string; value: string } => item !== null);
};

export const handleMultiSelectChange = (
  selectedOptions: MultiValue<SelectOption> | SingleValue<SelectOption>,
  onChange: (values: unknown | null) => void,
): void => {
  const values = Array.isArray(selectedOptions)
    ? selectedOptions.map((option) => Number(option.value))
    : selectedOptions
    ? [Number((selectedOptions as SelectOption).value)]
    : null;

  // Check if values is an empty array, and pass null instead
  onChange(values?.length === 0 ? null : values);
};

export const handleStringMultiSelectChange = (
  selectedOptions: MultiValue<SelectOption> | SingleValue<SelectOption>,
  onChange: (values: unknown | null) => void,
): void => {
  let values = [];
  if (selectedOptions) {
    if (Array.isArray(selectedOptions)) {
      values = selectedOptions.map((option) => option.value);
    } else if ("value" in selectedOptions && selectedOptions.value) {
      values = [(selectedOptions as SelectOption).value];
    }
  }
  onChange(values?.length === 0 ? [] : values);
};

export function isDateRangeObject(value: RuleValues): value is { from: Date; to: Date } {
  return typeof value === "object" && value !== null && "from" in value && "to" in value;
}

export function isNumberArray(value: RuleValues): value is number[] {
  return Array.isArray(value) && value.every((item) => typeof item === "number");
}

export const isSelectOption = (option: SelectOption | GroupedOption): option is SelectOption => {
  return "value" in option;
};

export const areGroupedOptions = (
  options: SelectOption[] | GroupedOption[],
): options is GroupedOption[] => {
  return (options as GroupedOption[]).every((option) => "options" in option);
};

export const arrayToObject = (array: { [key: string]: string }[]): { [key: string]: string } => {
  return array.reduce((acc, item) => {
    return { ...acc, ...item };
  }, {});
};

export const camelToSnake = (camelCaseString: string): string => {
  return camelCaseString.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
};

export const prioritizeRules = (rules: RulesType[]): RulesType[] => {
  const prioritizedRuleTypes = new Set([
    "learning_progress_system_decide",
    "learning_progress_belongs_to_branches",
    "learning_progress_belongs_to_groups",
    "learning_progress_belongs_to_list",
  ]);

  const prioritizedRules: RulesType[] = [];
  const otherRules: RulesType[] = [];

  rules.forEach((rule) => {
    if (prioritizedRuleTypes.has(rule.rule_type)) {
      prioritizedRules.push(rule);
    } else {
      otherRules.push(rule);
    }
  });

  return [...prioritizedRules, ...otherRules];
};

export const isValidDateFormat = (dateString: string | Date): boolean => {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

  return iso8601Regex.test(`${dateString}`);
};

export const isValidCustomFieldDateFormat = (dateString: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
};
