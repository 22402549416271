import React, { FC, useState } from "react";
import { Button, Grid } from "@epignosis_llc/gnosis";
import { AddOperatorSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { themesContainer } from "./styles";
import ThemeDialog from "./ThemeDialog";
import { Page, Skeletons, TableHeader, ThemeCard } from "@components";
import { generalNotification, getResponsiveGridValues, setActiveTheme } from "@utils/helpers";
import queryKeys from "@constants/queryKeys";
import { deleteTheme, getThemes, putActiveTheme } from "@api/themes";
import { defaultThemes } from "@constants/themes";
import { useConfigurationStore } from "@stores";
import { Theme } from "types/entities";
import ConfirmationModal from "@components/ReusableModals/ConfirmationModal";
import { AxiosError } from "axios";
import { handleNoPermissionError, handleDeleteThemeErrors } from "@errors";
import { URLS } from "@constants/urls";
import { useApplyTranslations } from "@hooks";

const getDefaultThemes = (activeThemeId: string): Theme[] =>
  defaultThemes.map((theme) => ({ ...theme, active: theme.id === activeThemeId }));
const SELECTED_TAB = "portalSettings";
const backLink = `${URLS.accountAndSettings.accountAndSettings}?tab=${SELECTED_TAB}`;

const CustomizeTheming: FC = () => {
  const { t } = useApplyTranslations();
  const gridCols = getResponsiveGridValues();
  const queryClient = useQueryClient();
  const domainSettings = useConfigurationStore((state) => state.domainSettings);

  const [selectedTheme, setSelectedTheme] = useState<Theme>(
    domainSettings?.theme ?? defaultThemes[0],
  );
  const [themeDialogIsOpen, setThemeDialogIsOpen] = useState<boolean>(false);
  const [editSelectedTheme, setEditSelectedTheme] = useState<Theme | null>(null);
  const [deletionSelectedTheme, setDeletionSelectedTheme] = useState<Theme | null>(null);

  const { data, status, error } = useQuery([queryKeys.themes.themes], () => getThemes(), {
    select: (res) => res._data,
  });

  const selectedIsNotActive = selectedTheme.id !== domainSettings?.theme.id;
  const themes = [...getDefaultThemes(domainSettings?.theme?.id ?? ""), ...(data ?? [])];

  // set the selected theme as currently active
  const handleThemePrieview = (theme: Theme): void => {
    setSelectedTheme(theme);
    setActiveTheme(theme);
  };

  // save selected theme as the active one
  const hanldeSetThemeActive = (): void => {
    putActiveThemeMutation(selectedTheme.id);
  };

  const { mutate: putActiveThemeMutation, isLoading: putActiveThemeMutationLoading } = useMutation(
    queryKeys.themes.active,
    putActiveTheme,
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("themes.setActiveSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.domainSettings]);
        queryClient.invalidateQueries([queryKeys.themes.themes]);
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  // reset the currently selected theme as active
  const resetSelectedTheme = (): void => {
    const activeTheme = domainSettings?.theme ?? defaultThemes[0];
    setSelectedTheme(activeTheme);
    setActiveTheme(activeTheme);
  };

  // close theme dialog
  const handleCloseThemeDialog = (): void => {
    setEditSelectedTheme(null);
    setThemeDialogIsOpen(false);
  };

  // open theme dialog to creat new theme
  const handleThemeCreate = (): void => {
    setEditSelectedTheme(null);
    setThemeDialogIsOpen(true);
  };

  // open theme dialog to edit theme
  const handleThemeEdit = (theme: Theme): void => {
    if (!theme.default) {
      setEditSelectedTheme(theme);
      setThemeDialogIsOpen(true);
    }
  };

  // select them for deletion
  const handleThemeDelete = (theme: Theme): void => {
    setDeletionSelectedTheme(theme);
  };

  // delete theme
  const handleThemeDeleteConfirmation = (): void => {
    if (deletionSelectedTheme && !deletionSelectedTheme.default) {
      // reset when the selected theme is deleted
      if (selectedTheme.id === deletionSelectedTheme.id) {
        resetSelectedTheme();
      }

      deleteThemeMutation(deletionSelectedTheme.id);
    }
  };

  const { mutate: deleteThemeMutation } = useMutation(queryKeys.themes.delete, deleteTheme, {
    onSuccess: () => {
      generalNotification("success", <p>{t("themes.deletedSuccessfully")}</p>);
      queryClient.invalidateQueries([queryKeys.themes.themes]);
      setDeletionSelectedTheme(null);
    },
    onError: (error: AxiosError) => {
      handleDeleteThemeErrors(error);
    },
  });

  return (
    <Page pageTitle={t("themes.customizeTheming")}>
      <TableHeader title={t("themes.customizeTheming")} backLink={backLink} />
      <div css={themesContainer}>
        <Skeletons.Loader
          error={error}
          status={status}
          loader={
            <Grid templateColumns={gridCols}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                <Skeletons.Card key={n} />
              ))}
            </Grid>
          }
        >
          <div className="themes-container">
            <Grid templateColumns={gridCols}>
              {themes.map((theme) => (
                <ThemeCard
                  key={theme.id}
                  theme={theme}
                  onThemePreview={handleThemePrieview}
                  onThemeEdit={handleThemeEdit}
                  onThemeDelete={handleThemeDelete}
                />
              ))}

              <Button
                variant="outline"
                className="add-new"
                onClick={handleThemeCreate}
                iconBefore={AddOperatorSVG}
              >
                {t("themes.addNew")}
              </Button>
            </Grid>
          </div>

          {selectedIsNotActive && (
            <div className="selected-theme-container">
              <Button isLoading={putActiveThemeMutationLoading} onClick={hanldeSetThemeActive}>
                {t("themes.setActive")}
              </Button>
              <Button color="secondary" onClick={resetSelectedTheme}>
                {t("general.cancel")}
              </Button>
            </div>
          )}
        </Skeletons.Loader>
      </div>

      {themeDialogIsOpen && (
        <ThemeDialog
          isOpen={themeDialogIsOpen}
          theme={editSelectedTheme}
          onHandleClose={handleCloseThemeDialog}
        />
      )}

      {deletionSelectedTheme && (
        <ConfirmationModal
          id="delete-theme-dialog"
          header={t("themes.delete")}
          bodyTitle={
            <p
              dangerouslySetInnerHTML={{
                __html: t("themes.deleteConfirmation", { theme: deletionSelectedTheme.name }),
              }}
            />
          }
          bodyText={t("general.actionCantBeUndone")}
          footerButton={t("general.delete")}
          isOpen={Boolean(deletionSelectedTheme)}
          onClose={(): void => setDeletionSelectedTheme(null)}
          onConfirm={(): void => handleThemeDeleteConfirmation()}
        />
      )}
    </Page>
  );
};

export default CustomizeTheming;
