import React, { FC, SyntheticEvent, useMemo } from "react";
import { Sidebar } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { MainNavData } from "./MainNav";
import { mainMenuInner } from "./MainMenu.styles";
// Menu Items
import MenuWithSubmenus from "./components/MenuWithSubmenus";

import { isMainMenuItemActiveForDrawer } from "@utils/helpers";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import { useConfigurationStore, useUserPreferencesStore } from "@stores";
import queryKeys from "@constants/queryKeys";
import permissions from "@utils/permissions";
import { getCalendatStatistics } from "@api/calendar";
import { featureFlags } from "@config";
import authService from "@utils/services/AuthService";
import { getMenuEntries } from "./helpers";
import { SerializedStyles } from "@emotion/react";
import userRoles from "@constants/userRoles";
import HelpMenuItem from "./components/HelpMenuItem";
import { getGradingHubCount } from "@api/gradingHub";
import { useCms } from "@hooks";

type MainMenuProps = {
  isCollapsed?: boolean;
  onToggle?: () => void;
  topMenuOnClick?: (e: SyntheticEvent) => void;
  bottomMenuOnClick?: (e: SyntheticEvent, string: MainDrawerContent) => void;
  navState?: MainNavData;
};

const MainMenu: FC<MainMenuProps> = ({
  isCollapsed = false,
  navState = null,
  bottomMenuOnClick = (): void => undefined,
}) => {
  const useUserStore = useUserPreferencesStore();
  const { customPages } = useCms({ isPublic: false });
  const { resetSettings } = useUserStore() ?? {};
  const { hideMainDrawer, setPendingAssignments, setPendingILTs } = useUIStore((state) => state);
  const { userProfileData, domainSettings } = useConfigurationStore();
  const { cms } = domainSettings ?? {};
  const menuEntries = getMenuEntries({ cms: customPages });
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === userRoles.LEARNER;
  const isInstructor = userRole === userRoles.INSTRUCTOR;
  const isAdmin = userRole === userRoles.ADMINISTRATOR;
  const isImpersonationEnabled = userProfileData?.impersonated;
  const isCustomHomePageEnabled = cms && cms?.published;
  const hasCustomPages = customPages && customPages?.length > 0;

  // permissions related
  const { canAccessCourses, canAccessUnionCourses, canUpdateCourseUnits } =
    permissions.coursesPermissions;
  const { canAccessCatalog } = permissions.catalogPermissions;
  const { canAccessCalendar } = permissions.calendarPermissions;
  const { canReadBranches } = permissions.branchPermissions;
  const { canExport, canImport } = permissions.importExportPermissions;
  const { canAccessUsers } = permissions.usersPermissions;
  const { canReadGroups } = permissions.groupPermissions;
  const { canAccessTimeline, canAccessReports, canAccessCourseReports } =
    permissions.reportsPermissions;
  const { canAccessAccount, canAccessStoreWithSubscription, canAccessSubscription } =
    permissions.accountPermissions;
  const { canAccessAutomations } = permissions.automationsPermissions;
  const { canAccessNotifications } = permissions.notificationsPermissions;
  const { canAccessCategories } = permissions.categoriesPermissions;
  const { canAccessUserTypes } = permissions.userTypesPermissions;
  const { canAccessHelp } = permissions.helpCenterPermissions;
  const { canAccessConferences } = permissions.conferencesPermissions;

  const allowCatalogView = canAccessCatalog();
  const allowMyCoursesView = isLearner && canAccessCourses();
  const allowAccountAndSettingsView =
    !isLearner &&
    featureFlags.accountAndSettings &&
    (canAccessAccount() ||
      canExport() ||
      canImport() ||
      canAccessCategories() ||
      canAccessUserTypes());
  const allowCoursesView = !isLearner && (canAccessCourses() || canAccessUnionCourses());
  const allowCalendarView = canAccessCalendar();
  const allowBranchesView = canReadBranches();
  const allowUsersView = canAccessUsers();
  const allowGroupsView = canReadGroups();
  const allowUpdateCourseUnits = canUpdateCourseUnits();
  const allowGradingHubView = isInstructor && allowUpdateCourseUnits;

  const allowReportsView =
    ((isAdmin ? canAccessReports() : canAccessCourseReports()) || canAccessTimeline()) &&
    featureFlags.reports &&
    !isLearner;
  const allowAutomationsView = canAccessAutomations() && isAdmin;
  const allowNotificationsView = canAccessNotifications() && isAdmin;
  const allowCourseStoreView = canAccessStoreWithSubscription() && canAccessCourses() && isAdmin;
  const allowSubscriptionView = isAdmin && canAccessSubscription();
  const allowHelpCenterView = canAccessHelp() && featureFlags.helpCenter;
  const allowConferencesView = canAccessConferences() && isInstructor;

  const { data: calendarEvents } = useQuery(queryKeys.calendar.statistics, getCalendatStatistics, {
    select: (eventCalendarRes) => ({
      data: eventCalendarRes._data,
    }),
    enabled: allowCalendarView,
  });

  const { data: pendingCount } = useQuery([queryKeys.gradingHub.pendingCount], getGradingHubCount, {
    select: (res) => res._data,
    onSuccess: (res) => {
      setPendingAssignments(res.pending_assignments);
      setPendingILTs(res.pending_ilts);
    },
    enabled: allowGradingHubView,
  });
  const handleBottomMenuClick = (
    e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>,
    drawerKey: string,
  ): void => {
    // redirect in dashboard and open onboarding tour

    bottomMenuOnClick(e, drawerKey as MainDrawerContent);
  };
  const { pending_assignments = 0, pending_ilts = 0 } = pendingCount ?? {};
  const totalPendingItems = pending_assignments + pending_ilts;

  // filter primary menu items based on the permissions
  const primaryMenuEntries = useMemo(() => {
    return menuEntries[0].filter((menuEntry) => {
      if (menuEntry.drawerKey === "role") return featureFlags.roleSwitching; //role switching feature
      if (menuEntry.drawerKey === "catalog") return allowCatalogView;
      if (menuEntry.drawerKey === "myCourses") return allowMyCoursesView;
      if (menuEntry.drawerKey === "accountAndSettings") return allowAccountAndSettingsView;
      if (menuEntry.drawerKey === "courses") return allowCoursesView;
      if (menuEntry.drawerKey === "calendar") return allowCalendarView;
      if (menuEntry.drawerKey === "branches") return allowBranchesView;
      if (menuEntry.drawerKey === "groups") return allowGroupsView;
      if (menuEntry.drawerKey === "gradingHub") return allowGradingHubView;
      if (menuEntry.drawerKey === "users") return allowUsersView;
      if (menuEntry.drawerKey === "reports") return allowReportsView;
      if (menuEntry.drawerKey === "courseStore") return allowCourseStoreView;
      if (menuEntry.drawerKey === "automations") return allowAutomationsView;
      if (menuEntry.drawerKey === "notifications") return allowNotificationsView;
      if (menuEntry.drawerKey === "billingAndSubscriptionSettings") return allowSubscriptionView;
      if (menuEntry.drawerKey === "customPages") return isCustomHomePageEnabled && hasCustomPages;
      if (menuEntry.drawerKey === "conferences") return allowConferencesView;
      if (menuEntry.drawerKey === "home") return true;
      if (menuEntry.drawerKey === "profile") return true;
      if (menuEntry.drawerKey === "dashboard") return true;
      return false;
    });
  }, [
    menuEntries,
    allowCatalogView,
    allowMyCoursesView,
    allowAccountAndSettingsView,
    allowCoursesView,
    allowCalendarView,
    allowBranchesView,
    allowGroupsView,
    allowGradingHubView,
    allowUsersView,
    allowReportsView,
    allowCourseStoreView,
    allowAutomationsView,
    allowNotificationsView,
    isCustomHomePageEnabled,
    hasCustomPages,
    allowSubscriptionView,
    allowConferencesView,
  ]);

  // filter secondary menu items based on the permissions
  const secondaryMenuEntries = useMemo(() => {
    return menuEntries[1].filter((menuEntry) => {
      if (menuEntry.drawerKey === "helpCenter") return allowHelpCenterView; //help center feature
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuEntries, isImpersonationEnabled, allowHelpCenterView]);

  const getBadgeNumber = (drawerKey: string): number => {
    if (drawerKey === "calendar") return calendarEvents?.data.upcoming ?? 0;
    if (drawerKey === "gradingHub") return totalPendingItems ?? 0;
    return 0;
  };

  const handleTopMenuClick = (): void => {
    resetSettings();
    hideMainDrawer();
  };

  return (
    <Sidebar isCollapsed={isCollapsed} className="sidebar-container">
      <div
        className="inner-container"
        css={(theme): SerializedStyles => mainMenuInner(theme, { isCollapsed })}
      >
        <ul id="main-menu" className="main-menu">
          {primaryMenuEntries.map(({ url, title, icon, drawerKey, submenu }) => {
            const withBadge = ["calendar", "gradingHub"].some((item) => item === drawerKey);

            return (
              <MenuWithSubmenus
                key={title}
                title={title}
                url={url}
                icon={icon}
                drawerKey={drawerKey}
                withBadge={withBadge}
                badgeNumber={getBadgeNumber(drawerKey ?? "")}
                isCollapsed={isCollapsed}
                submenu={submenu}
                handleClick={handleTopMenuClick}
              />
            );
          })}
        </ul>

        <ul className="secondary-menu">
          {secondaryMenuEntries.map(({ title, icon, drawerKey }) => {
            return (
              <HelpMenuItem
                key={title}
                title={title}
                drawerKey={drawerKey ?? ""}
                icon={icon}
                isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
                isCollapsed={isCollapsed}
                handleClick={(e, drawerKey): void =>
                  handleBottomMenuClick(e, drawerKey as MainDrawerContent)
                }
              />
            );
          })}
        </ul>
      </div>
    </Sidebar>
  );
};

export default MainMenu;
