import React, { FC, useState } from "react";
import { Button, Modal } from "@epignosis_llc/gnosis";
import { modalFooter } from "./styles";
import { ImageEditor } from "@components";
import { useApplyTranslations } from "@hooks";

type ImageModalModalProps = {
  isOpen: boolean;
  imageSrc: string;
  cropAspectRatio?: number;
  imageSelectorTitle?: string;
  onClose: () => void;
  onSave: (canvas: HTMLCanvasElement) => void;
};

const ImageModal: FC<ImageModalModalProps> = ({
  isOpen,
  imageSrc,
  cropAspectRatio = 1,
  imageSelectorTitle = "general.profilePhoto",
  onClose,
  onSave,
}) => {
  const { t } = useApplyTranslations();
  const [croppedCanvas, setCroppedCanvas] = useState<HTMLCanvasElement>();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Header>{t(imageSelectorTitle)}</Modal.Header>
      <Modal.Body>
        <ImageEditor
          imageSrc={imageSrc}
          aspectRatio={cropAspectRatio}
          onCropChanged={setCroppedCanvas}
        />
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button onClick={(): void => onSave(croppedCanvas as HTMLCanvasElement)}>
            {t("general.saveChanges")}
          </Button>
          <Button type="button" color="secondary" onClick={onClose} className="cancel-btn">
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
