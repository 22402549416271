import { css } from "@emotion/react";

export const themesContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  .themes-container {
    .add-new {
      height: 100%;
      flex-direction: column;
      padding: 29.5% 0;

      svg {
        border: 2px solid;
        margin-bottom: 1rem;
        padding: 0.5rem;
        border-radius: 50%;
        height: 2.25rem;
        width: 2.25rem;
      }
    }
  }

  .selected-theme-container {
    padding-top: 2rem;

    button {
      margin-inline-end: 1rem;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }
  }
`;

export const themeDialogFooter = css`
  button {
    margin-inline-end: 1rem;

    &:last-of-type {
      margin-inline-end: 0;
    }
  }
`;
