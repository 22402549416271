import React from "react";
import axios from "axios";
import { useConfigurationStore } from "@stores";
import { DomainSettings, UploadValidation, FileValidationsObj, CourseFile } from "types/entities";
import { convertBytesToSizeUnit } from "@utils/helpers";
import { t } from "@utils/i18n";
import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { FileCard, PdfViewer } from "@components";
import { MediaPlayer } from "@epignosis_llc/gnosis";

type DownloadFileArgs = {
  fileUrl: string;
  fileName: string;
  isDirectDownload?: boolean; // This is used to download the file directly from the URL instead of making a fetch request.
  fileId?: number;
};
// TODO: params should be an object for better readability.
export const downloadFile = async ({
  fileUrl,
  fileName,
  isDirectDownload = false,
  fileId,
}: DownloadFileArgs): Promise<void> => {
  const { canAccessProfile } = permissions.profilePermissions;
  const canViewProfile = canAccessProfile();
  const defaultRole = authService.getDefaultRole();
  const isLearner = defaultRole === "learner";
  const link = createLinkElement();
  link.href = fileUrl;
  link.download = fileName;

  if (link.origin !== location.origin && !isDirectDownload) {
    const corsEnabled = await isCorsEnabled(fileUrl);

    if (corsEnabled) {
      await getFile(fileUrl);
      return;
    } else {
      // force to open file in new tab when getting CORS error
      link.target = "_blank";
    }

    // We register the download to the timeline if the user is a learner and has access to the profile
    // Even if the actual action could not be performed, we still want to register the download when the user tries to do so
    const shouldRegisterDownloadToTimeline = isLearner && canViewProfile && fileId;
    if (shouldRegisterDownloadToTimeline) {
      await registerFileDownload(fileId);
    }
  }

  link.click();
};

const createLinkElement = (): HTMLAnchorElement => {
  const link = document.createElement("a");
  link.rel = "noopener";

  return link;
};

const isCorsEnabled = async (url: string): Promise<boolean> => {
  try {
    const response = await axios({
      url: url,
      method: "HEAD",
    });

    return response?.status >= 200 && response?.status <= 299;
  } catch {
    return false;
  }
};

const registerFileDownload = async (fileId: number): Promise<void> => {
  try {
    await HttpClient.post(ENDPOINTS.files.register(fileId));
  } catch (err) {
    return;
  }
};

const getFile = async (fileUrl: string): Promise<void> => {
  const filenameFromUrl = getFilenameFromUrl(fileUrl);

  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Disposition": "attachment",
      filename: filenameFromUrl,
    },
  });

  if (response.status === 200) {
    const link = createLinkElement();
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute("download", filenameFromUrl);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getFileFromBlobUrl = async (
  blobUrl: string,
  fileName: string,
  fileOptions?: FilePropertyBag,
): Promise<File> => {
  return await axios.get(blobUrl, { responseType: "blob" }).then((response) => {
    return new File([response.data], fileName, fileOptions);
  });
};

export const getFileValidations = (
  paths: string | string[],
): UploadValidation | UploadValidation[] | null => {
  const { getState } = useConfigurationStore;
  const domainSettings = getState().domainSettings;
  let validations: UploadValidation | UploadValidation[] | null = null;

  if (Array.isArray(paths)) {
    validations = paths.reduce((array, path) => {
      array.push((domainSettings as DomainSettings).upload_limits[path]);
      return array;
    }, [] as UploadValidation[]);
  }

  if (typeof paths === "string") {
    validations = (domainSettings as DomainSettings).upload_limits[paths];
  }

  return validations;
};

export const buildFileValidations = (
  validations: UploadValidation[] | UploadValidation | null,
): FileValidationsObj => {
  let result: FileValidationsObj | null = null;

  if (Array.isArray(validations)) {
    // is array of objects
    const obj = validations
      .flatMap(({ mime_types, size_limit }) =>
        mime_types.map((mimeType) => ({ [mimeType]: size_limit })),
      )
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  } else {
    // is object
    const { mime_types: mimeTypes, size_limit: sizeLimit } = validations as UploadValidation;
    const obj = mimeTypes
      .map((mimeType) => ({ [mimeType]: sizeLimit }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  }

  return result;
};

export const getFilenameFromUrl = (url: string): string => {
  const filename = new URL(url).pathname.split("/").pop() as string;
  return filename;
};

export const validateSelectedFiles = ({
  files,
  acceptedFileExtensions = [],
  mimeTypeAndFilesizeValidations,
  selectedFiles,
  maxFiles,
  onFileError,
}: {
  files: FileList;
  acceptedFileExtensions?: string[];
  onFileError: (error: string) => void;
  mimeTypeAndFilesizeValidations: FileValidationsObj;
  selectedFiles: File[];
  maxFiles: number;
}): File[] => {
  // save current files number
  let currentFilesNumber = selectedFiles.length;
  let filesToAdd: File[] = [];
  const acceptedFileTypes = Object.keys(mimeTypeAndFilesizeValidations);

  Array.from(files).forEach((file): boolean => {
    // exceeds files limit
    if (currentFilesNumber >= maxFiles) {
      onFileError(t("validationFiles.filesAllowedToUpload", { count: maxFiles }));

      return true;
    }

    // validate with file mime type
    // check if file has an accepted mime type
    if (!acceptedFileExtensions.length && !acceptedFileTypes.includes(file.type)) {
      onFileError(`${t("notifications.fileTypeNotSupported")}`);

      return true;
    }

    // validate with file extension
    // check if file has an accepted extension or an accepted mime type
    if (acceptedFileExtensions.length) {
      const filename: string = file.name;
      const fileExtension = filename.split(".").pop()?.toLowerCase() as string;
      const hasAcceptedExtension = acceptedFileExtensions.includes(fileExtension);
      const hasAcceptedMimeType = acceptedFileTypes.includes(file.type);

      if (!hasAcceptedExtension && !hasAcceptedMimeType) {
        onFileError(`${t("notifications.fileTypeNotSupported")}`);

        return true;
      }
    }

    // file exceeds file size limit
    if (file.size > mimeTypeAndFilesizeValidations[file.type]) {
      onFileError(
        t("validationFiles.fileSizeExceedLimit", {
          number: convertBytesToSizeUnit(mimeTypeAndFilesizeValidations[file.type], "MB"),
          type: "MB",
        }),
      );

      return true;
    }

    currentFilesNumber++;
    filesToAdd = [...filesToAdd, file] as File[];

    return true;
  });

  return filesToAdd;
};

export const arrayToFileList = (files: File[]): FileList => {
  const fileList = {};

  files.forEach((file, index) => {
    fileList[index] = file;
  });

  return fileList as FileList;
};

export const filePreview = (file: CourseFile): JSX.Element => {
  const { mime_type, url, name } = file;

  switch (mime_type) {
    case "video/mp4":
      return <MediaPlayer type="video" src={url} />;
    case "audio/mpeg":
      return <MediaPlayer type="audio" src={url} />;
    case "application/pdf":
      return (
        <div className="pdf-wrapper">
          <PdfViewer fileUrl={url} />
        </div>
      );
    case "image/gif":
    case "image/png":
    case "image/jpeg":
      return <img src={url} alt={name} />;
    default:
      return (
        <div className="card-wrapper">
          <FileCard {...file} />
        </div>
      );
  }
};
