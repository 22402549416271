import { convertBase64ToBlob } from "@utils/helpers";

export function buildImageFile(
  croppedCanvas: HTMLCanvasElement,
  imageFile: File | null,
): File | null {
  if (!imageFile) return null;

  const name = imageFile?.name.slice(0, imageFile.name.lastIndexOf("."));
  const extension = imageFile?.name.split(".").pop();
  const fileType = imageFile?.type ? imageFile?.type : "image/png";

  const newFileData = {
    name,
    extension: fileType === "image/gif" ? "png" : extension,
    type: fileType === "image/gif" ? "image/png" : fileType,
  };

  const dataUrl = croppedCanvas.toDataURL(newFileData.type);
  const blob = convertBase64ToBlob(dataUrl);

  const imgFile = new File([blob], `${newFileData.name}.${newFileData.extension}`, {
    type: newFileData.type,
  });

  return imgFile;
}
