import { Column, TableProps } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { QueryFilter, SelectOption } from "types/common";
import { Pagination as IPagination } from "types/responses/index";
import { camelToSnake } from "./helpers";

export enum ReportType {
  Users = "users",
  LearningProgress = "learning_progress",
}

export interface Rule {
  value: string;
  label: string;
  config?: RuleConfiguration;
}

export type ExtraData =
  | "branches"
  | "groups"
  | "courses"
  | "period"
  | "userTypes"
  | "fieldValue"
  | "points"
  | "level"
  | "anyString"
  | "email";

export interface OutputField {
  label: string;
  value: string;
  default?: boolean;
}

export type RulesType =
  | {
      usersFilters: Rule[];
    }
  | {
      coursesFilters: Rule[];
    };

export interface CustomReportFormData {
  name: string;
  type: ReportType;
  rules: RulesType;
  output: OutputField[];
}

export const getDropdownOptions = (): SelectOption[] => {
  return [
    {
      value: ReportType.Users,
      label: "Specific users",
    },
    {
      value: ReportType.LearningProgress,
      label: "Learning progress",
    },
  ];
};

export function getUserReportOutputColumns(): {
  label: string;
  value: string;
  default: boolean;
}[] {
  return [
    { label: t("users.user"), value: "user", default: true },
    { label: t("signUp.email"), value: "email", default: true },
    { label: t("signIn.username"), value: "username", default: false },
    { label: t("users.userType"), value: "userType", default: true },
    { label: t("reports.customReports.registrationDate"), value: "registration", default: true },
    { label: t("reports.customReports.lastLogin"), value: "lastLogin", default: true },
    { label: t("general.points"), value: "points", default: false },
    {
      label: t("widgetsDashboard.overview.assignedCourses"),
      value: "assignedCourses",
      default: true,
    },
    {
      label: t("dashboard.leaderboardTabs.completedCourses"),
      value: "completedCourses",
      default: true,
    },
    { label: t("microstats.completion_rate"), value: "completionRate", default: false },
    { label: t("general.groups_other"), value: "groups", default: false },
    { label: t("general.branches_other"), value: "branches", default: false },
    { label: t("general.badges"), value: "badges", default: false },
    { label: t("general.level"), value: "level", default: false },
    { label: t("reports.customReports.userId"), value: "userId", default: false },
  ];
}

export function getLearningProgressReportOutputColumns(): {
  label: string;
  value: string;
  default: boolean;
}[] {
  return [
    { label: t("users.user"), value: "user", default: true },
    { label: t("signUp.email"), value: "email", default: true },
    { label: t("branches.courses.course"), value: "course", default: true },
    { label: t("reports.customReports.enrolledOn"), value: "courseEnrolledOn", default: true },
    { label: t("general.status"), value: "courseStatus", default: true },
    { label: t("reports.customReports.timeSpent"), value: "courseTime", default: true },
    {
      label: t("reports.customReports.timeSpentInSeconds"),
      value: "courseTimeSeconds",
      default: false,
    },
    {
      label: t("reports.customReports.completionDate"),
      value: "courseCompletionDate",
      default: false,
    },
    { label: t("reports.customReports.averageScore"), value: "courseAverageScore", default: false },
    {
      label: t("reports.customReports.courseExpiration"),
      value: "courseExpirationDate",
      default: false,
    },
    { label: t("certificates.certificate"), value: "certification", default: false },
    { label: t("reports.customReports.certificateId"), value: "certificationId", default: false },
    {
      label: t("reports.customReports.certificateDate"),
      value: "certificationDate",
      default: false,
    },
    {
      label: t("reports.customReports.certificateExpiration"),
      value: "certificationExpiration",
      default: false,
    },
    { label: t("reports.customReports.remainingTime"), value: "remainingTime", default: false },
    { label: t("signIn.username"), value: "username", default: false },
    { label: t("reports.customReports.lastLogin"), value: "lastLogin", default: false },
    { label: t("general.points"), value: "points", default: false },
    { label: t("general.groups_other"), value: "groups", default: false },
    { label: t("general.branches_other"), value: "branches", default: false },
    { label: t("general.level"), value: "level", default: false },
    { label: t("reports.customReports.userId"), value: "userId", default: false },
    { label: t("reports.customReports.courseId"), value: "courseId", default: false },
  ];
}

export interface RuleConfiguration {
  label: string;
  component?: ComponentType;
  extraData?: ExtraData;
  hasNextXDaysWording?: boolean;
}

export type ComponentType = "TextInput" | "Select" | "DateRange" | "FieldValue";

export function getUsersRuleConfigurations(): Record<string, RuleConfiguration> {
  return {
    belongs_to_branch: {
      label: t("reports.customReports.belongToBranch"),
      component: "Select",
      extraData: "branches",
    },
    not_belongs_to_branch: {
      label: t("reports.customReports.doNotBelongToBranch"),
      component: "Select",
      extraData: "branches",
    },
    belongs_to_group: {
      label: t("reports.customReports.belongToGroup"),
      component: "Select",
      extraData: "groups",
    },
    not_belongs_to_group: {
      label: t("reports.customReports.doNotBelongToGroup"),
      component: "Select",
      extraData: "groups",
    },
    has_course: {
      label: t("reports.customReports.haveTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_not_course: {
      label: t("reports.customReports.doNotHaveTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    assigned_course_within_period: {
      label: t("reports.customReports.haveBeenEnrolledToCourse"),
      component: "DateRange",
      extraData: "period",
    },
    completed_course: {
      label: t("reports.customReports.haveCompletedTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    not_completed_course: {
      label: t("reports.customReports.haveNotCompletedTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    completed_course_within_period: {
      label: t("reports.customReports.haveCompletedTheCourseWithin"),
      component: "DateRange",
      extraData: "period",
    },
    not_completed_course_within_period: {
      label: t("reports.customReports.haveNotCompletedTheCourseWithin"),
      component: "DateRange",
      extraData: "period",
    },
    started_uncompleted_course: {
      label: t("reports.customReports.haveStartedButNotCompletedCourse"),
      component: "Select",
      extraData: "courses",
    },
    course_expired: {
      label: t("reports.customReports.courseExpired"),
      component: "Select",
      extraData: "courses",
    },
    uncompleted_expiring_course_within_period: {
      label: t("reports.customReports.haveNotCompletedCourseExpiring"),
      component: "DateRange",
      extraData: "period",
      hasNextXDaysWording: true,
    },
    has_course_certification: {
      label: t("reports.customReports.haveCertificateForCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_expired_course_certification: {
      label: t("reports.customReports.haveExpiredCertificateForCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_expiring_certification_within_period: {
      label: t("reports.customReports.haveExpiringCertificate"),
      component: "DateRange",
      extraData: "period",
      hasNextXDaysWording: true,
    },
    earned_certification_within_period: {
      label: t("reports.customReports.earnedCertificateWithin"),
      component: "DateRange",
      extraData: "period",
    },
    logged_in_within_period: {
      label: t("reports.customReports.loggedInWithin"),
      component: "DateRange",
      extraData: "period",
    },
    not_logged_within_period: {
      label: t("reports.customReports.haveNotLoggedInWithin"),
      component: "DateRange",
      extraData: "period",
    },
    signed_up_within_period: {
      label: t("reports.customReports.signedUpWithin"),
      component: "DateRange",
      extraData: "period",
    },
    has_user_type: {
      label: t("reports.customReports.hasUserType"),
      component: "Select",
      extraData: "userTypes",
    },
    has_specific_custom_field_value: {
      label: t("reports.customReports.hasSpecificCustomField"),
      component: "FieldValue",
      extraData: "fieldValue",
    },
    is_active: {
      label: t("reports.customReports.isActive"),
    },
    is_inactive: {
      label: t("reports.customReports.isInactive"),
    },
    has_points: {
      label: t("reports.customReports.hasPoints"),
      component: "TextInput",
      extraData: "points",
    },
    reached_level_or_higher: {
      label: t("reports.customReports.reachedLevelOrHigher"),
      component: "TextInput",
      extraData: "level",
    },
    not_accepted_tos: {
      label: t("reports.customReports.notAcceptedTos"),
    },
    accepted_tos: {
      label: t("reports.customReports.acceptedTos"),
    },
    email_contains: {
      label: t("reports.customReports.emailContains"),
      component: "TextInput",
      extraData: "email",
    },
  };
}

export function getLearningProgressRuleConfigurations(): Record<string, RuleConfiguration> {
  return {
    learning_progress_system_decide: {
      label: t("reports.customReports.learningProgressSystemDecide"),
    },
    learning_progress_belongs_to_branches: {
      label: t("reports.customReports.learningProgressBelongsToBranches"),
      component: "Select",
      extraData: "branches",
    },
    learning_progress_belongs_to_groups: {
      label: t("reports.customReports.learningProgressBelongsToGroups"),
      component: "Select",
      extraData: "groups",
    },
    learning_progress_belongs_to_list: {
      label: t("reports.customReports.learningProgressBelongsToList"),
      component: "Select",
      extraData: "courses",
    },
  };
}

const PAGINATION: IPagination = { number: 1, size: 100 };
const CUSTOM_PAGINATION: IPagination = { number: 1, size: 10 };
const DEFAULT_FILTERS: QueryFilter[] = [{ key: "[status]", value: "active" }];
const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };

export function getDefaultState(paginationSize: 10 | 100): {
  pagination: IPagination;
  sorting: TableProps["sorting"] | undefined;
  filters: QueryFilter[];
  url: string;
} {
  return {
    pagination: paginationSize === 100 ? PAGINATION : CUSTOM_PAGINATION,
    sorting: SORTING,
    filters: DEFAULT_FILTERS,
    url: ``,
  };
}

export enum Columns {
  User = "user",
  Email = "email",
  Username = "username",
  UserType = "userType",
  Registration = "registration",
  LastLogin = "lastLogin",
  Points = "points",
  AssignedCourses = "assignedCourses",
  CompletedCourses = "completedCourses",
  CompletionRate = "completionRate",
  Groups = "groups",
  Branches = "branches",
  Course = "course",
  CourseStatus = "courseStatus",
  CourseTime = "courseTime",
  CourseTimeSeconds = "courseTimeSeconds",
  CourseEnrolledOn = "courseEnrolledOn",
  CourseCompletionDate = "courseCompletionDate",
  CourseAverageScore = "courseAverageScore",
  CourseExpirationDate = "courseExpirationDate",
  Certification = "certification",
  CertificationId = "certificationId",
  CertificationDate = "certificationDate",
  CertificationExpiration = "certificationExpiration",
  RemainingTime = "remainingTime",
  Badges = "badges",
  Level = "level",
  UserId = "userId",
  CourseId = "courseId",
}

type CustomColumnFieldName = `customUserField${number}` | `customCourseField${number}`;

type ColumnFields =
  | "user"
  | "email"
  | "username"
  | "user_type"
  | "registration"
  | "last_login"
  | "points"
  | "assigned_courses"
  | "completed_courses"
  | "completion_rate"
  | "groups"
  | "branches"
  | "course"
  | "course_status"
  | "course_time"
  | "course_time_seconds"
  | "course_enrolled_on"
  | "course_completion_date"
  | "course_average_score"
  | "course_expiration_date"
  | "certification"
  | "certification_id"
  | "certification_date"
  | "certification_expiration"
  | "remaining_time"
  | "badges"
  | "level"
  | "user_id"
  | "course_id";

export type ColumnFieldsType = ColumnFields | CustomColumnFieldName;

export const getCustomReportColumns = (columns: ColumnFieldsType[]): Column[] => {
  const transformedColumns = columns.map((column) => {
    return camelToSnake(column);
  });

  return [
    {
      accessor: Columns.User,
      cell: t("users.user"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.User),
    },
    {
      accessor: Columns.Email,
      cell: t("signUp.email"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Email),
    },
    {
      accessor: "user_type",
      cell: t("users.userType"),
      headerWidth: 250,
      hidden: !transformedColumns.includes("user_type"),
    },
    {
      accessor: Columns.Registration,
      cell: t("reports.customReports.registrationDate"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Registration),
    },
    {
      accessor: "last_login",
      cell: t("reports.customReports.lastLogin"),
      headerWidth: 250,
      hidden: !transformedColumns.includes("last_login"),
    },
    {
      accessor: "assigned_courses",
      cell: t("widgetsDashboard.overview.assignedCourses"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("assigned_courses"),
    },
    {
      accessor: "completed_courses",
      cell: t("dashboard.leaderboardTabs.completedCourses"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("completed_courses"),
    },
    {
      accessor: Columns.Username,
      cell: t("signIn.username"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Username),
    },
    {
      accessor: Columns.Points,
      cell: t("general.points"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Points),
    },
    {
      accessor: "completion_rate",
      cell: t("microstats.completion_rate"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("completion_rate"),
    },
    {
      accessor: Columns.Groups,
      cell: t("general.groups_other"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Groups),
    },
    {
      accessor: Columns.Branches,
      cell: t("general.branches_other"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Branches),
    },
    {
      accessor: Columns.Badges,
      cell: t("general.badges"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Badges),
    },
    {
      accessor: Columns.Level,
      cell: t("general.level"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Level),
    },
    {
      accessor: "user_id",
      cell: t("reports.customReports.userId"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("user_id"),
    },
    {
      accessor: Columns.Course,
      cell: t("branches.courses.course"),
      headerWidth: 150,
      hidden: !transformedColumns.includes(Columns.Course),
    },
    {
      accessor: "course_enrolled_on",
      cell: t("reports.customReports.enrolledOn"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_enrolled_on"),
    },
    {
      accessor: "course_status",
      cell: t("general.status"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_status"),
    },
    {
      accessor: "course_time",
      cell: t("reports.customReports.timeSpent"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_time"),
    },
    {
      accessor: "course_time_seconds",
      cell: t("reports.customReports.timeSpentInSeconds"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_time_seconds"),
    },
    {
      accessor: "course_completion_date",
      cell: t("reports.customReports.completionDate"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_completion_date"),
    },
    {
      accessor: "course_average_score",
      cell: t("reports.customReports.averageScore"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_average_score"),
    },
    {
      accessor: "certification",
      cell: t("certificates.certificate"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("certification"),
    },
    {
      accessor: "certification_id",
      cell: t("reports.customReports.certificateId"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("certification_id"),
    },
    {
      accessor: "course_expiration_date",
      cell: t("reports.customReports.courseExpiration"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_expiration_date"),
    },
    {
      accessor: "certification_date",
      cell: t("reports.customReports.certificateDate"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("certification_date"),
    },
    {
      accessor: "certification_expiration",
      cell: t("reports.customReports.certificateExpiration"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("certification_expiration"),
    },
    {
      accessor: "course_id",
      cell: t("reports.customReports.courseId"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("course_id"),
    },
    {
      accessor: "remaining_time",
      cell: t("reports.customReports.remainingTime"),
      headerWidth: 150,
      hidden: !transformedColumns.includes("remaining_time"),
    },
  ];
};

export const customReportActions = [
  {
    accessor: "actions",
    cell: "",
    classNames: ["hidden"],
    sortableHeader: false,
  },
];

export const customReportMassActionsWordings = {
  activate: {
    progressHeader: "general.activate",
    progressMessage: "users.massActions.activatingUsers",
    confirmationBodyTitle: "users.massActions.activateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationHeader: "general.activate",
    confirmationButton: "general.activate",
    successMessage: "users.massActions.activateUsersSuccess",
  },
  deactivate: {
    progressHeader: "general.deactivate",
    progressMessage: "users.massActions.deactivatingUsers",
    confirmationHeader: "general.deactivate",
    confirmationButton: "general.deactivate",
    successMessage: "users.massActions.deactivateUsersSuccess",
    confirmationBodyTitle: "users.massActions.deactivateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_branch: {
    progressHeader: "branches.courses.addToBranch",
    progressMessage: "users.massActions.addingUsersToBranches",
    confirmationHeader: "branches.courses.addToBranch",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_branch: {
    progressHeader: "users.massActions.removeUsersFromBranches",
    progressMessage: "users.massActions.removingUsersFromBranches",
    confirmationHeader: "users.massActions.removeUsersFromBranches",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_group: {
    progressHeader: "courses.addToGroup",
    progressMessage: "users.massActions.addingUsersToGroups",
    confirmationHeader: "courses.addToGroup",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_group: {
    progressHeader: "users.massActions.removeUsersFromGroups",
    progressMessage: "users.massActions.removingUsersFromGroups",
    confirmationHeader: "users.massActions.removeUsersFromGroups",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_course: {
    progressHeader: "courses.massActions.enrollModalTitle",
    progressMessage: "courses.massActions.enrollProgress",
    confirmationHeader: "courses.massActions.enrollModalTitle",
    confirmationButton: "general.enroll",
    successMessage: "courses.massActions.enrollSuccess",
    confirmationBodyTitle: "courses.massActions.enrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_course: {
    progressHeader: "courses.massActions.unenrollModalTitle",
    progressMessage: "courses.massActions.unenrollProgress",
    confirmationHeader: "courses.massActions.unenrollModalTitle",
    confirmationButton: "general.unenroll",
    successMessage: "courses.massActions.unenrollSuccess",
    confirmationBodyTitle: "courses.massActions.unenrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  custom_reports_reset_progress: {
    progressHeader: "reports.customReports.resetProgressOnCourse",
    progressMessage: "reports.customReports.resettingProgressOnCourse",
    confirmationHeader: "reports.customReports.resetProgressOnCourse",
    confirmationButton: "general.apply",
    successMessage: "reports.customReports.resetProgressOnCourseSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  custom_reports_delete_users: {
    progressHeader: "general.delete",
    progressMessage: "reports.customReports.deletingUsers",
    confirmationHeader: "general.delete",
    confirmationButton: "general.delete",
    successMessage: "reports.customReports.deleteUserSuccess",
    confirmationBodyTitle: "reports.customReports.confirmUserDelete",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
};
