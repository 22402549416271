// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Drawer, Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { InfoButtonSVG, ArrowLeftSVG, CloseSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import { SerializedStyles } from "@emotion/react";
import { Placement } from "tippy.js";

// Styles
import { ActionDrawerContainer } from "./styles";

// Utils
import { i18n } from "@utils/i18n";
import { useApplyTranslations } from "@hooks";
import { DrawerSize } from "types/common";

export type ActionDrawerProps = {
  isOpen: boolean;
  headerTitle: string;
  headerSubtitle?: string;
  showFooter?: boolean;
  hasBackButton?: boolean;
  fullscreen?: boolean;
  size?: DrawerSize;
  id?: string;
  onClose: () => void;
  onApply?: () => void;
  actionButton?: {
    text?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    tooltipContent?: string;
  };
  customFooter?: JSX.Element;
  tooltipContent?: string;
  tooltipPlacement?: Placement;
};

const widthDimensions = {
  xs: "24rem",
  sm: "28rem",
  md: "48rem",
  lg: "72rem",
};

const DEFAULT_SIZE = "xs";

const ActionDrawer: FC<ActionDrawerProps> = ({
  isOpen,
  onClose,
  onApply,
  headerTitle,
  headerSubtitle,
  showFooter = true,
  hasBackButton = false,
  fullscreen = false,
  size = DEFAULT_SIZE,
  id,
  children,
  actionButton = {
    text: "",
    isDisabled: false,
    isLoading: false,
    tooltipContent: "",
  },
  customFooter,
  tooltipContent,
  tooltipPlacement = "bottom",
}) => {
  const { t } = useApplyTranslations();
  const { lg } = useResponsive();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "left" : "right";
  const width = lg ? widthDimensions[size] : widthDimensions[DEFAULT_SIZE];
  const actionButtonText = actionButton?.text ? actionButton?.text : t("general.apply");
  const { isDisabled, isLoading, tooltipContent: buttonTooltipContent } = actionButton;

  return (
    <Drawer
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      width={fullscreen ? "100%" : width}
      css={(theme): SerializedStyles =>
        ActionDrawerContainer(theme, { hasSubtitle: Boolean(headerSubtitle) })
      }
    >
      <Drawer.Header closable={false}>
        <div className="back-title-container">
          {hasBackButton && (
            <Button
              color="secondary"
              variant="link"
              onClick={onClose}
              noGutters
              className="back-button"
            >
              <ArrowLeftSVG height={28} />
            </Button>
          )}
          <div className="header-content">
            <Heading as="h3" data-testid={`${id ? id : "drawer"}-header`}>
              <div className="header-container">
                {headerTitle}
                {tooltipContent && (
                  <Tooltip as="span" content={tooltipContent} placement={tooltipPlacement}>
                    <InfoButtonSVG className="tooltip-icon" height={16} />
                  </Tooltip>
                )}
              </div>
            </Heading>
            {headerSubtitle && <Text fontSize="xs">{headerSubtitle}</Text>}
          </div>
        </div>
        {/* Show the close button when there is no footer or we are below lg screen */}
        {(!showFooter || !lg) && (
          <Button
            color="secondary"
            variant="link"
            onClick={onClose}
            noGutters
            className="close-button"
          >
            <CloseSVG height={20} />
          </Button>
        )}
      </Drawer.Header>
      <Drawer.Body>
        <section className="action-drawer-body">{children}</section>
      </Drawer.Body>
      {showFooter && (
        <Drawer.Footer>
          {customFooter ? (
            customFooter
          ) : (
            <>
              {buttonTooltipContent ? (
                <Tooltip
                  content={<div dangerouslySetInnerHTML={{ __html: buttonTooltipContent }} />}
                >
                  <Button
                    onClick={onApply ? onApply : onClose}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    className="left-aligned"
                    data-testid="drawer-save"
                  >
                    {actionButtonText}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={onApply ? onApply : onClose}
                  disabled={isDisabled}
                  isLoading={isLoading}
                  className="left-aligned"
                  data-testid="drawer-save"
                >
                  {actionButtonText}
                </Button>
              )}

              <Button color="secondary" data-testid="drawer-cancel" onClick={onClose}>
                {t("general.cancel")}
              </Button>
            </>
          )}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default ActionDrawer;
