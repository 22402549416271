import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["imageSelector"] => {
  return {
    color: colors.black,
    hoverColor: colors.primary.lighter,
    wrapperHoverColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
  };
};
